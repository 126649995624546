import { Component, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { EventConstants, localStorageConstants } from 'src/app/common/constants/localStorage-constants';

//Services
import { StorageService } from '../../../common/services/service/storage.service';
import { CommonService } from 'src/app/common/services/service/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent implements DoCheck {

  URLConstants = URLConstants;
  operatorName: string;
  operatorLogo: string;
  localStorageConstants = localStorageConstants;
  eventConstants = EventConstants;

  constructor(private storageService: StorageService, public router: Router, private commonService: CommonService) { }

  ngDoCheck() {
    this.operatorName = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.OperatorName) : this.storageService.getSessionToken(this.localStorageConstants.OperatorName);
    this.operatorLogo = Number(this.storageService.getLocalToken(this.localStorageConstants.KeepMeSignIn)) ? this.storageService.getLocalToken(this.localStorageConstants.OperatorLogo) : this.storageService.getSessionToken(this.localStorageConstants.OperatorLogo);
  }

  help() {
    this.eventManager();
    window.open('https://skillsbase.io/contact-us', '_blank');
  }

  eventManager() {
    const eventData = {
      EventName: this.eventConstants.Event.Clicked.name,
      EventCode: this.eventConstants.Event.Clicked.code,
      ScreenName: this.eventConstants.Screen.Help.name,
      ScreenCode: this.eventConstants.Screen.Help.code,
    };
    this.commonService.eventManager(true, eventData);
  }

}





